import { HAListing } from "../types/pms-hostway-listing";
import { useApi } from "./useApi";
import { useLazyApi } from "./useLazyApi";

export type HABookableListing = HAListing & {
  blockedDates: { start: string; end: string }[];
};

export const useProperty = (listingId: number) => {
  return useApi<HABookableListing, void>(`/v1/guest/property/${listingId}`);
};

export const usePropertyPrice = (listingId: number) => {
  return useLazyApi<
    {
      baseRate: number;
      vat: number;
      monthlyDiscount?: number;
      weeklyDiscount?: number;
      cleaningFee: number;
      totalPrice: number;
      pricePerExtraPerson?: number;
    },
    {
      arrivalDate: string;
      departureDate: string;
      numberOfGuests: number;
      prolongingReservationId?: number;
    }
  >(`/v1/guest/property/${listingId}/price`);
};
