import axios from "axios";
import { apiEndpoint } from "../config";
import { PaymentProductCodes } from "../Guest/PaymentProductCodes";
import { InvoiceForm } from "../Guest/types/InvoiceForm";
import { getAuthHeader } from "./common";
import { ReservationDateRange } from "../Discovery/components/StayDatePickerRange";

export interface ICreateOwnerReservationData {
  hostawayId: string;
  guestFirstName: string;
  guestLastName: string;
  checkinDate: string;
  checkoutDate: string;
}

export interface IProlongReservationData {
  guestEmail?: string;
  reservationId: string;
  checkoutDate: string;
}

type PaymentProductCodestatus = "booked" | "unavailable" | "available";

export interface GuestPublicContext {
  blockedDates: ReservationDateRange[];
  coverImageUrl: string;
  reservationId: number;
  hasCheckedIn: boolean;
  canAccessCheckinInstructions: boolean;
  canRequestInvoice: boolean;
  listingId: string;
  obfuscatedEmail?: string;
  checkoutDate: string;
  checkinDate: string;
  listingName: string;
  totalPrice: number;
  totalPaid: number;
  paid: boolean;
  depositRequired: boolean;
  depositPaid: boolean;
  status: "inquiry" | "new" | "modified" | "ownerStay" | "cancelled";
  earlyCheckin: PaymentProductCodestatus;
  babyCot: PaymentProductCodestatus;
  lateCheckout: PaymentProductCodestatus;
}

// PUBLIC
const getProductCheckoutUrl = async (
  reservationId: number,
  productCode: PaymentProductCodes
): Promise<{ url: string }> =>
  (
    await axios.post(
      `${apiEndpoint}/v1/payments/product/${productCode}/${reservationId}`
    )
  ).data;

const getReservationPaymentUrl = async (
  reservationId: number
): Promise<{ url: string }> =>
  (await axios.post(`${apiEndpoint}/v1/payments/reservation/${reservationId}`))
    .data;

const getDepositPaymentUrl = async (
  reservationId: number
): Promise<{ url: string }> =>
  (await axios.post(`${apiEndpoint}/v1/payments/deposit/${reservationId}`))
    .data;

// PUBLIC
const getGuestReservation = async (id: string): Promise<GuestPublicContext> =>
  (
    await axios.get(
      `${apiEndpoint}/v1/guest/reservations/manager/reservation/${id}`
    )
  ).data;

const createOwnerReservation = async (
  data: ICreateOwnerReservationData
): Promise<void> =>
  await axios.post(
    `${apiEndpoint}/v1/customer/reservations/manager`,
    data,
    await getAuthHeader()
  );

// PUBLIC
const createProlongation = async (
  data: IProlongReservationData
): Promise<{
  guestPortalUrl: string;
  id: string;
  totalPrice: number;
  paymentUrl?: string;
}> =>
  (
    await axios.post<{
      guestPortalUrl: string;
      id: string;
      paymentUrl?: string;
      totalPrice: number;
    }>(`${apiEndpoint}/v1/guest/reservations/manager/prolong`, data)
  ).data;

const getReservationInvoice = async (reservationId: string) =>
  (
    await axios.get<{
      reservation_id: string;
      invoice_external_id: string;
      invoice_number: string;
    }>(`${apiEndpoint}/v1/guest/invoicing/${reservationId}`)
  ).data || undefined;

const createReservationInvoice = async (
  reservationId: string,
  data: InvoiceForm
) =>
  (
    await axios.post<{
      reservation_id: string;
      invoice_external_id: string;
      invoice_number: string;
    }>(`${apiEndpoint}/v1/guest/invoicing/${reservationId}`, data)
  ).data || undefined;

const downloadReservationInvoice = async (reservationId: string) =>
  (
    await axios.get<Blob>(
      `${apiEndpoint}/v1/guest/invoicing/${reservationId}/download`,
      { responseType: "blob" }
    )
  ).data;

export const useCustomerApi = () => {
  return {
    createOwnerReservation,
    createProlongation,
    createReservationInvoice,
    downloadReservationInvoice,
    getDepositPaymentUrl,
    getGuestReservation,
    getProductCheckoutUrl,
    getReservationInvoice,
    getReservationPaymentUrl,
  };
};
