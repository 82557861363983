import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GuestPublicContext, useCustomerApi } from "../../hooks/useCustomerApi";
import { useSessionContext } from "./SessionProvider";

export interface ReservationContextInterface {
  reservation: GuestPublicContext | undefined;
  setReservation: (reservation: GuestPublicContext) => void;
}

const ReservationContext = createContext<
  ReservationContextInterface | undefined
>(undefined);

export default ReservationContext;

export const useReservationContext = () => {
  const context = useContext(ReservationContext);

  if (!context) {
    throw new Error(
      "useReservationContext must be used within a ReservationProvider"
    );
  }

  return context;
};

export const ReservationProvider = ({ children }: PropsWithChildren) => {
  const [reservation, setReservation] = useState<GuestPublicContext>();
  const { getGuestReservation } = useCustomerApi();
  const { session } = useSessionContext();

  useEffect(() => {
    (async () => {
      if (!session) return;

      try {
        setReservation(
          await getGuestReservation(String(session.reservationId))
        );
      } catch (error) {
        setReservation(undefined);
      }
    })();
  }, [session, getGuestReservation]);

  return (
    <ReservationContext.Provider value={{ reservation, setReservation }}>
      {children}
    </ReservationContext.Provider>
  );
};
